<template>
  <ion-page>

    <!-- Page Content -->
    <ion-content>
      <!-- Header -->
      <ModalHeader page="add-venue" />
      <!-- Home Feed -->
      <div class="app-max-width">
        <!-- If User is Authorized -->
        <div v-if="isAuth === true">
          <!-- Search -->
          <div v-if="formView == 'search'">
            <!-- Search -->
            <ion-searchbar @ion-change="handleInput($event)"></ion-searchbar>
            <!-- Add Venue button -->
            <ion-item v-if="showAddVenueButton === true">
              <div tabindex="0"></div>
              <ion-button @click="formView = 'add-venue'">Add venue</ion-button>
            </ion-item>   
            <!-- Matched venues --> 
            <ion-list v-if="matchedVenues.length > 0">
              <ion-item v-for="venue in matchedVenues" :key="venue" @click.prevent="addVenue(venue)" button detail>
                <div tabindex="0"></div>
                <ion-label style="margin-left:5px;">
                  <h2 class="text-cap">{{venue.name}}</h2>
                  <p>{{venue.address}}</p>            
                </ion-label>
              </ion-item>
            </ion-list>
          </div>
          <!-- Add place -->
          <div v-else>
            <!-- Add Venue button -->
            <ion-item button>
              <div tabindex="0"></div>
              <ion-button @click="formView = 'search'" color="light">Search Venues</ion-button>
            </ion-item> 
            <PlaceFormWrapper :store="store.state" :user-id="0" :place-name="keyword" @place-details="getPlaceDetails"/>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonSearchbar, IonItem, IonList, IonLabel, IonButton } from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
import doAuth from '../../services/auth';
import ModalHeader from '../../components/ModalHeader.vue';
import { useStore } from "vuex";
import apiClient from '../../services/api';
import PlaceFormWrapper from '../../components/PlaceFormWrapper.vue'
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'AddVenue',
  components: {
    IonContent, IonPage, IonSearchbar, IonItem, IonList, IonLabel, IonButton, PlaceFormWrapper, ModalHeader
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const keyword = ref(null);
    const matchedVenues = ref([]);
    const venues = ref([]);
    const showAddVenueButton = ref(false);
    const formView = ref('search');

    onMounted(() => {
      getVenues()
    })

    function getVenues() {
      apiClient.get('/api/get/places/type/venue',
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          venues.value = response.data
        }).catch(error => {
          console.log(error)
        });        
    }

    function handleInput(event) {
      keyword.value = event.target.value.toLowerCase();
      matchedVenues.value = [];
      for(var i=0; i<venues.value.length;i++) {
        var venue = venues.value[i].name.toLowerCase()
        if(venue.includes(keyword.value)) {
          matchedVenues.value.push({
            id: venues.value[i].id,
            name: venue,
            address: venues.value[i].formatted_address
          })
        }
      }
      if(keyword.value.length > 0) {
        showAddVenueButton.value = true
      }
    }

    function addVenue(venue) {
      // commit venue to store
      store.commit('setVenue', venue)
      router.push('/create-concert')
    }

    return {
      getVenues, keyword, venues,  handleInput, matchedVenues, showAddVenueButton, formView, addVenue, router, store
    }
  },
  methods: {
    getPlaceDetails: function(e) {
      this.addVenue({
        id: e.id,
        name: e.name,
        address: e.formatted_address
      })
    } 
  },
  watch: {
    keyword: function() {
      if(this.keyword === null || this.keyword == '') {
        this.matchedVenues = []
      }
    }
  }
});
</script>

<style scoped>
  :root {
    --ion-safe-area-top: 20px;
    --ion-safe-area-bottom: 22px;
  }
.text-cap {
  text-transform:capitalize!important;
}
</style>